import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './ContactCard.css';

const ContactCard = ({imgSrc, title, desc, link}) => {

    function openLink(param) {
        if (param !== false) window.location.href = param;
    }

    return (
        <div onClick={() => openLink(link)} className="contact-card">
            

            <div id={title} className="card" style={{ cursor: link !== false ? 'pointer' : 'default' }}>
                <>
                <img
                    alt={title + " icon"}
                    src={imgSrc}
                        className="card-img-top contactIcon"
                />
                </>
                 <div className="custom-card-body card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className={title === "Email" ? "shrink-email contact-text card-text" : "contact-text card-text"}>
                        {desc}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ContactCard;